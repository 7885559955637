import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import DynamicTitle from "./DynamicTitle";

function Home() {
  return (
    <div className="flex flex-col min-h-screen">
      <DynamicTitle>Home</DynamicTitle>
      <Header />

      <main className="flex-grow">
        {/* Hero Section */}
        <section className="bg-hero-pattern bg-cover bg-center text-white py-20">
          <div className="max-w-screen-xl mx-auto px-4 text-center">
            <h1 className="text-4xl sm:text-5xl font-bold mb-4">
              Unlock Healing with EMDR Therapy
            </h1>
            <p className="text-lg sm:text-xl mb-8">
              Begin your journey toward mental wellness today.
            </p>
            <Link
              to="/subscribe"
              className="inline-block bg-accent hover:bg-primary text-white font-semibold py-3 px-6 rounded-full"
            >
              Start Your Free Trial
            </Link>
          </div>
        </section>

        {/* Introduction to EMDR */}
        <section className="py-12">
          <div className="max-w-screen-md mx-auto px-4 text-center">
            <h2 className="text-2xl font-bold mb-4">What is EMDR Therapy?</h2>
            <p className="text-base mb-4">
              EMDR (Eye Movement Desensitization and Reprocessing) is a
              psychotherapy treatment designed to alleviate the distress
              associated with traumatic memories.
            </p>
            <Link
              to="/about-emdr"
              className="text-accent hover:underline font-medium"
            >
              Learn More &rarr;
            </Link>
          </div>
        </section>

        {/* Features & Benefits */}
        <section className="bg-gray-50 py-12">
          <div className="max-w-screen-xl mx-auto px-4">
            <h2 className="text-2xl font-bold text-center mb-8">
              Why Choose Realign EMDR Therapy?
            </h2>
            <div className="flex flex-wrap -mx-4">
              {/* Feature 1 */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="text-center">
                  <img
                    src="/images/healfromtrauma.png"
                    alt="Heal from Trauma"
                    className="mx-auto mb-4 w-20 h-20"
                  />
                  <h3 className="text-xl font-semibold mb-2">
                    Heal from Trauma
                  </h3>
                  <p className="text-base">
                    Process traumatic memories and move towards healing.
                  </p>
                </div>
              </div>
              {/* Feature 2 */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="text-center">
                  <img
                    src="/images/scriptedsessions.webp"
                    alt="Scripted Sessions"
                    className="mx-auto mb-4 w-20 h-20"
                  />
                  <h3 className="text-xl font-semibold mb-2">
                    Scripted Sessions
                  </h3>
                  <p className="text-base">
                    Access sessions created by certified EMDR therapists.
                  </p>
                </div>
              </div>
              {/* Feature 3 */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="text-center">
                  <img
                    src="/images/flexibleaccess.webp"
                    alt="Flexible Access"
                    className="mx-auto mb-4 w-20 h-20"
                  />
                  <h3 className="text-xl font-semibold mb-2">
                    Flexible Access
                  </h3>
                  <p className="text-base">
                    Engage in therapy sessions anytime, anywhere.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section className="py-12">
          <div className="max-w-screen-xl mx-auto px-4">
            <h2 className="text-2xl font-bold text-center mb-8">
              What Our Clients Say
            </h2>
            <div className="flex flex-wrap -mx-4">
              {/* Testimonial 1 */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  <p className="text-base mb-4">
                    "Realign EMDR Therapy has been a game-changer in my healing
                    journey."
                  </p>
                  <p className="font-semibold">- Alex M.</p>
                </div>
              </div>
              {/* Testimonial 2 */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  <p className="text-base mb-4">
                    "The guided sessions are so accessible and effective."
                  </p>
                  <p className="font-semibold">- Jamie L.</p>
                </div>
              </div>
              {/* Testimonial 3 */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  <p className="text-base mb-4">
                    "I love the flexibility of unguided sessions on my own
                    time."
                  </p>
                  <p className="font-semibold">- Taylor R.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* How It Works */}
        <section className="bg-gray-50 py-12">
          <div className="max-w-screen-xl mx-auto px-4">
            <h2 className="text-2xl font-bold text-center mb-8">
              How It Works
            </h2>
            <div className="flex flex-wrap -mx-4">
              {/* Step 1 */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="text-center">
                  <div className="text-4xl font-bold text-accent mb-4">1</div>
                  <h3 className="text-xl font-semibold mb-2">Sign Up</h3>
                  <p className="text-base">
                    Create your account and choose a subscription plan.
                  </p>
                </div>
              </div>
              {/* Step 2 */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="text-center">
                  <div className="text-4xl font-bold text-accent mb-4">2</div>
                  <h3 className="text-xl font-semibold mb-2">
                    Select a Session
                  </h3>
                  <p className="text-base">
                    Choose from guided or unguided EMDR sessions.
                  </p>
                </div>
              </div>
              {/* Step 3 */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="text-center">
                  <div className="text-4xl font-bold text-accent mb-4">3</div>
                  <h3 className="text-xl font-semibold mb-2">Begin Healing</h3>
                  <p className="text-base">
                    Start your journey towards mental wellness.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Additional Call to Action */}
        <section className="py-12">
          <div className="max-w-screen-md mx-auto px-4 text-center">
            <h2 className="text-2xl font-bold mb-4">
              Ready to Start Your Healing Journey?
            </h2>
            <p className="text-base mb-8">
              Join our community and take the first step today.
            </p>
            <Link
              to="/subscribe"
              className="inline-block bg-accent hover:bg-primary text-white font-semibold py-3 px-6 rounded-full"
            >
              Subscribe Now
            </Link>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default Home;
