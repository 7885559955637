import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Header from "./Header";
import Footer from "./Footer";
import DynamicTitle from "./DynamicTitle";

function Contact() {
  const [state, handleSubmit] = useForm("xvgodnqb"); // Replace with your Formspree ID

  return (
    <div className="flex flex-col min-h-screen">
      <DynamicTitle>Contact Us</DynamicTitle>
      <Header />

      <main className="flex-grow">
        <section className="py-12">
          <div className="max-w-screen-md mx-auto px-4">
            <h1 className="text-3xl font-bold mb-4 text-center text-secondary">
              Contact Us
            </h1>
            <p className="text-base mb-8 text-center text-textSecondary">
              We'd love to hear from you. Please fill out the form below, and
              we'll get back to you as soon as possible.
            </p>

            {state.succeeded ? (
              <div className="text-center">
                <h2 className="text-2xl font-bold text-green-600 mb-4">
                  Thank You!
                </h2>
                <p className="text-base text-textSecondary">
                  Your message has been sent successfully. We'll get back to you
                  shortly.
                </p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Name Field */}
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-accent focus:ring-accent"
                  />
                  <ValidationError
                    prefix="Name"
                    field="name"
                    errors={state.errors}
                    className="text-red-600 text-sm mt-1"
                  />
                </div>

                {/* Email Field */}
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email Address
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-accent focus:ring-accent"
                  />
                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                    className="text-red-600 text-sm mt-1"
                  />
                </div>

                {/* Message Field */}
                <div>
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    required
                    rows="5"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-accent focus:ring-accent"
                  ></textarea>
                  <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                    className="text-red-600 text-sm mt-1"
                  />
                </div>

                {/* Submit Button */}
                <div className="text-center">
                  <button
                    type="submit"
                    disabled={state.submitting}
                    className="inline-block bg-accent hover:bg-secondary text-white font-semibold py-3 px-6 rounded-full"
                  >
                    {state.submitting ? "Sending..." : "Send Message"}
                  </button>
                </div>
              </form>
            )}
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default Contact;
