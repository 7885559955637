import React, { useState, useContext, useEffect } from "react";
import Modal from "./Modal";
import DotMovement from "./DotMovement";
import DotContext from "../contexts/DotContext";
import { useNavigate } from "react-router-dom";
import MoodTracker from "./MoodTracker";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import DynamicTitle from "./DynamicTitle";

function UnguidedSession() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showDotMovement, setShowDotMovement] = useState(false);
  const [moodModalVisible, setMoodModalVisible] = useState(true);
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState();
  const {
    bgColor,
    dotColor,
    dotSize,
    dotSpeed,
    isPaused,
    setIsPaused,
    setSidebarVisible,
    sidebarVisible,
    soundFile,
    mute,
  } = useContext(DotContext);
  const [preSessionMood, setPreSessionMood] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken.user_id);
    }
  }, []);

  const endDotMovement = () => {
    setShowDotMovement(false);
    setIsModalVisible(true);
  };

  const { exitSession, setExitSession } = useContext(DotContext);

  useEffect(() => {
    if (exitSession) {
      setIsModalVisible(false);
      setShowDotMovement(false);
      setMoodModalVisible(true);
      setExitSession(false);
    }
  }, [exitSession, setExitSession]);

  const handleMoodSubmit = async (mood, note, type) => {
    const payload =
      type === "pre"
        ? {
            user_id: userId,
            mood_pre: mood,
            comment_pre: note,
            session_type: "Unguided",
          }
        : {
            mood_post: mood,
            comment_post: note,
          };

    const apiURL =
      type === "pre" ? "/api/sessions" : `/api/sessions/${sessionId}`;
    const method = type === "pre" ? "POST" : "PATCH";

    try {
      const response = await axios({
        method,
        url: apiURL,
        data: payload,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (type === "pre") {
        setSessionId(response.data.session_id);
        setIsModalVisible(true);
        setMoodModalVisible(false);
        setPreSessionMood(mood);
      } else if (type === "post") {
        setSidebarVisible(false);
        navigate("/session-end");
      }
    } catch (error) {
      console.error("Error submitting mood:", error);
    }
  };

  const toggleNav = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const handleUserChoice = (continueSession) => {
    if (continueSession) {
      setShowDotMovement(true);
      setIsModalVisible(false);
    } else {
      setIsModalVisible(false);
      setMoodModalVisible(true);
    }
  };

  const renderModalContent = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto">
        <p className="text-text mb-4">Do you want to continue?</p>
        <div className="flex gap-2">
          <button
            onClick={() => handleUserChoice(true)}
            className="bg-accent text-background font-semibold py-2 px-4 rounded hover:bg-primary transition duration-200"
          >
            Yes
          </button>
          <button
            onClick={() => handleUserChoice(false)}
            className="bg-accent text-background font-semibold py-2 px-4 rounded hover:bg-primary transition duration-200"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`relative flex flex-col items-center justify-center h-screen ${bgColor}`}
    >
      <DynamicTitle>Unguided Session</DynamicTitle>
      {!sidebarVisible && (
        <button
          onClick={toggleNav}
          className="absolute top-4 left-4 text-xl text-text z-50 focus:outline-none"
        >
          <span className="hamburger" style={{ color: dotColor }}>&#9776;</span>
        </button>
      )}

      {moodModalVisible && (
        <MoodTracker
          onSubmit={(mood, note) =>
            handleMoodSubmit(mood, note, preSessionMood ? "post" : "pre")
          }
        />
      )}

      {isModalVisible && (
        <Modal open={isModalVisible} onClose={() => setIsModalVisible(false)}>
          {renderModalContent()}
        </Modal>
      )}

      {showDotMovement && (
        <div className="w-full h-full flex items-center justify-center relative">
          <button
            className="absolute bottom-4 bg-accent text-white py-2 px-6 rounded hover:bg-primary transition duration-200 z-50 font-bold"
            onClick={togglePause}
          >
            {isPaused ? "Resume" : "Pause"}
          </button>
          <DotMovement
            shouldRun={showDotMovement}
            isPaused={isPaused}
            bgColor={bgColor}
            dotColor={dotColor}
            dotSize={dotSize}
            dotSpeed={dotSpeed}
            onCompletion={endDotMovement}
            soundFile={soundFile}
            mute={mute}
          />
        </div>
      )}
    </div>
  );
}

export default UnguidedSession;
