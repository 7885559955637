import React from "react";
import { Link } from "react-router-dom";
import "./styles/Home.css";
import Footer from "./Footer";
import Header from "./Header";
import DynamicTitle from "./DynamicTitle";

function Disclaimer() {
  return (
    <div className="flex flex-col min-h-screen">
      <DynamicTitle>Disclaimer</DynamicTitle>
      <Header />
      <main className="flex-grow mx-auto px-4 sm:px-6 lg:px-8 max-w-screen-xl mt-4 mb-4">
        <h1 className="text-xl font-bold mb-6">Legal Disclaimer</h1>

        <p className="mt-2 text-base">
          RealignMind.com, operated by Realign LLC ("we," "us," or "our"),
          provides a self-guided mental health program based on the principles
          of Eye Movement Desensitization and Reprocessing ("EMDR") through an
          online platform ("the Program"). By using our website, mobile
          application, or any related services (collectively "the Platform"),
          you acknowledge and agree to the following:
        </p>

        <h2 className="text-lg font-semibold mt-4">
          1. Not a Substitute for Professional Care
        </h2>
        <p className="mt-2 text-base">
          The Program is intended for self-help purposes and is{" "}
          <strong>
            not a substitute for professional medical advice, diagnosis, or
            treatment
          </strong>
          . If you are experiencing a medical or mental health emergency,
          including suicidal thoughts or intent to harm yourself or others,{" "}
          <strong>call emergency services immediately</strong>.
        </p>

        <h2 className="text-lg font-semibold mt-4">2. Risks and Limitations</h2>
        <p className="mt-2 text-base">
          Use of the Platform is <strong>at your own risk</strong>, and we make
          no guarantees or representations regarding the outcomes or
          effectiveness of the Program. If you feel overwhelmed or distressed
          during a session, stop using the Platform and seek professional
          support.
        </p>

        <h2 className="text-lg font-semibold mt-4">
          3. No Emergency or Crisis Intervention
        </h2>
        <p className="mt-2 text-base">
          The Platform does not provide crisis intervention or emergency
          support.
          <strong>
            IF YOU ARE IN CRISIS OR NEED IMMEDIATE HELP, CONTACT EMERGENCY
            SERVICES IN YOUR AREA.
          </strong>
        </p>

        <h2 className="text-lg font-semibold mt-4">4. No Warranty</h2>
        <p className="mt-2 text-base">
          The Program and the Platform are provided on an{" "}
          <strong>"as is" and "as available"</strong> basis, with no warranties
          of any kind, either express or implied. We do not warrant that the
          Program will meet your requirements or that its operation will be
          uninterrupted or error-free.
        </p>

        <h2 className="text-lg font-semibold mt-4">
          5. Limitation of Liability
        </h2>
        <p className="mt-2 text-base">
          To the maximum extent permitted by law, Realign LLC shall not be
          liable for any direct, indirect, incidental, consequential, or
          punitive damages arising out of or related to your use of the
          Platform.
        </p>

        <h2 className="text-lg font-semibold mt-4">6. User Responsibility</h2>
        <p className="mt-2 text-base">
          By using the Platform, you affirm that you are at least 18 years old,
          or have obtained parental or guardian consent if under 18. You take
          full responsibility for your own physical, mental, and emotional
          well-being.
        </p>

        <p className="mt-2 text-base">
          By continuing to use our Platform, you acknowledge that you have read,
          understood, and agreed to this Legal Disclaimer. If you have
          questions, please contact us at <Link to="/contact">Contact Us</Link>.
        </p>
      </main>

      <Footer />
    </div>
  );
}

export default Disclaimer;
