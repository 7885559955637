// client/src/App.js
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import AboutEMDR from "./components/AboutEMDR";
import Contact from "./components/Contact";
import Confirmation from "./components/Confirmation";
import ResetPassword from "./components/ResetPassword";
import Subscribe from "./components/Subscribe";
import SessionSelection from "./components/SessionSelection";
import UnguidedSession from "./components/UnguidedSession";
import ARTSession from "./components/ARTSession";
import Sidebar from "./components/Sidebar";
import DotContext from "./contexts/DotContext";
import SessionHistory from "./components/SessionHistory";
import SignInSignUp from "./components/SignInSignUp";
import SessionEnd from "./components/SessionEnd";
import ErrorBoundary from "./components/ErrorBoundary";
import UserProfile from "./components/UserProfile";
import TermsOfService from "./components/TermsOfService";
import Disclaimer from "./components/Disclaimer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import ReturnPolicy from "./components/Refunds";
import CrisisPage from "./components/CrisisPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ForgotPassword from "./components/ForgotPassword";

function AppContent() {
  const { loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      {/* Home route should be accessible always */}
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/about-emdr" element={<AboutEMDR />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/confirmation" element={<Confirmation />} />
      <Route path="/subscribe" element={<Subscribe />} />
      <Route path="/login" element={<SignInSignUp />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/return-policy" element={<ReturnPolicy />} />
      <Route path="/emergency-support" element={<CrisisPage />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      {/* Protected routes */}
      <Route path="/" element={<ProtectedRoute />}>
        {/* Include trailing "*" to support nested routes */}
        <Route path="/sessions/*" element={<SessionSelection />} />
        <Route path="/sessions/art" element={<ARTSession />} />
        <Route path="/sessions/unguided" element={<UnguidedSession />} />
        <Route path="/session-history" element={<SessionHistory />} />
        <Route path="/session-end" element={<SessionEnd />} />
        <Route path="/profile" element={<UserProfile />} />
      </Route>
      {/* Redirect any unspecified routes to home */}
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

function App() {
  const [dotColor, setDotColor] = useState("#333333");
  const [bgColor, setBgColor] = useState("#FFF8F0");
  const [dotSize, setDotSize] = useState(20);
  const [dotSpeed, setDotSpeed] = useState(90);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [shouldRun, setShouldRun] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [exitSession, setExitSession] = useState(false);
  const [soundFile, setSoundFile] = useState("/sounds/Synth_Tick_C_lo.wav");
  const [mute, setMute] = useState(false);

  return (
    <AuthProvider>
      <ErrorBoundary>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <DotContext.Provider
          value={{
            dotColor,
            setDotColor,
            bgColor,
            setBgColor,
            dotSize,
            setDotSize,
            dotSpeed,
            setDotSpeed,
            sidebarVisible,
            setSidebarVisible,
            shouldRun,
            setShouldRun,
            isPaused,
            setIsPaused,
            exitSession,
            setExitSession,
            soundFile,
            setSoundFile,
            setMute,
            mute,
          }}
        >
          <Router>
            <Sidebar
              isVisible={sidebarVisible}
              closeSidebar={() => setSidebarVisible(false)}
              dotColor={dotColor}
              setDotColor={setDotColor}
              bgColor={bgColor}
              setBgColor={setBgColor}
              dotSize={dotSize}
              setDotSize={setDotSize}
              dotSpeed={dotSpeed}
              setDotSpeed={setDotSpeed}
              exitSession={exitSession}
              setExitSession={setExitSession}
            />
            <AppContent />
          </Router>
        </DotContext.Provider>
      </ErrorBoundary>
    </AuthProvider>
  );
}

export default App;
