import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DynamicTitle from "./DynamicTitle";

function ResetPassword() {
  const [checkingToken, setCheckingToken] = useState(true);
  const [displayForm, setDisplayForm] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const useQuery = () => {return new URLSearchParams(location.search);};
  const query = useQuery();
  const token = query.get("token");
  const email = encodeURIComponent(query.get("email"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.get(`/api/reset-password?token=${token}&email=${email}`);
        setCheckingToken(false);
        setDisplayForm(true);
      } catch (error) {
        setCheckingToken(false);
        setErrorMessage(handleError(error.response.status));
        console.error('Error checking token:', error);
      }
    };

    fetchData();
  }, []); 

  function handleError(code){
    console.log("handleError invoked! Error Code:", code)
      if (code >= 400 && code < 500){
        return "Your link has expired. Please request a new link.";
      }
      return "An error occurred. Please try again later.";
  }

  const validatePassword = (password) => {
    const passwordPattern =
      /^(?=.*[0-9])(?=.*[!@#$%^&*? ]).{8,}$/;
    console.log("This password is " + passwordPattern.test(password));
    return passwordPattern.test(password);
    // return true
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const apiUrl = `/api/reset-password?token=${token}&email=${email}`;
    console.log(`Sending request to: ${apiUrl}`);

    // Validate password
    if (!validatePassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include a number and a symbol.",
      );
      return;
    } else {
      setPasswordError("");
    }

    if (password !== passwordConfirm) {
      setPasswordMatchError(
        "Passwords should match.",
      );
      return;
    } else {
      setPasswordMatchError("");
    }

    try {
      setDisplayForm(false)
      setMessage("Resetting password...")
      const response = await axios.put(apiUrl, {
        password,
      });

      console.log("Response from server:", response.data);
      toast.success(response.data.message);
      setMessage("Success! Taking you back to the login page...")
      setTimeout(() => navigate('/login'), 1500)
    } catch (error) {
      setDisplayForm(true)
      setMessage("")
      setErrorMessage(handleError(error.response.status))
      console.error('Password Reset Failed', error);
      toast.error(error.response?.data?.error || error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <DynamicTitle>Reset Password</DynamicTitle>
      <Header />
      <main className="flex-grow mx-auto px-4 sm:px-6 lg:px-8 max-w-screen-xl mt-4 mb-4 flex flex-col items-center">
        <h1 className="text-2xl font-bold text-center">Reset Password</h1>
        {message && (
          <p className="text-green-500 text-center my-2">{message}</p>
        )}
        {checkingToken && (
          <p className="text-center my-2">
            Checking token...
          </p>
        )}
        {errorMessage && (
          <p className="text-red-500 text-center my-2">
           {errorMessage}
          </p>
        )}
        {displayForm && (<form onSubmit={handleSubmit} className="flex flex-col">
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              New Password
            </label>
            <input
              id="newPassword"
              type="password"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Confirm New Password
            </label>
            <input
              id="confirmPassword"
              type="password"
              placeholder="Confirm New Password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
            />
            {passwordError && (
              <span className="text-red-500 text-sm">{passwordError}</span>
            )}
            {passwordMatchError && (
              <span className="text-red-500 text-sm">{passwordMatchError}</span>
            )}
          </div>
          <button
            type="submit"
            className="subscribe-button text-white py-2 rounded-md transition"
          >
            Reset
          </button>
        </form>)}
      </main>
      <Footer />
    </div>
  );
}

export default ResetPassword;
