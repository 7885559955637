import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import DynamicTitle from "./DynamicTitle";

function AboutEMDR() {
  return (
    <div className="flex flex-col min-h-screen">
      <DynamicTitle>EMDR Info</DynamicTitle>
      <Header />

      <main className="flex-grow">
        {/* Hero Section */}
        <section className="bg-emdr-hero bg-cover bg-center text-white py-20">
          <div className="max-w-screen-xl mx-auto px-4 text-center">
            <h1 className="text-4xl sm:text-5xl font-bold mb-4">
              About EMDR Therapy
            </h1>
            <p className="text-lg sm:text-xl mb-8">
              Discover the history and principles behind EMDR therapy.
            </p>
          </div>
        </section>

        {/* What is EMDR? */}
        <section className="py-12">
          <div className="max-w-screen-md mx-auto px-4">
            <h2 className="text-2xl font-bold mb-4 text-center">
              What is EMDR Therapy?
            </h2>
            <p className="text-base mb-4">
              Eye Movement Desensitization and Reprocessing (EMDR) is a
              psychotherapy approach that enables people to heal from the
              symptoms and emotional distress resulting from disturbing life
              experiences. EMDR therapy shows that the mind can recover from
              psychological trauma much like the body recovers from physical
              trauma.
            </p>
            <p className="text-base">
              By addressing the unprocessed memories that cause negative
              emotions, EMDR therapy helps clients process these memories and
              develop healthier coping mechanisms.
            </p>
          </div>
        </section>

        {/* History of EMDR */}
        <section className="bg-gray-50 py-12">
          <div className="max-w-screen-md mx-auto px-4">
            <h2 className="text-2xl font-bold mb-4 text-center">
              The History of EMDR
            </h2>
            <p className="text-base mb-4">
              EMDR therapy was developed in 1987 by psychologist Dr. Francine
              Shapiro. She discovered that eye movements could reduce the
              intensity of disturbing thoughts when she noticed her own stress
              responses diminished as her eyes moved rapidly while walking
              through a park.
            </p>
            <p className="text-base">
              Since then, EMDR has evolved through rigorous research and
              development, becoming a well-established and effective treatment
              method for trauma and other mental health issues.
            </p>
          </div>
        </section>

        {/* How EMDR Works */}
        <section className="py-12">
          <div className="max-w-screen-xl mx-auto px-4">
            <h2 className="text-2xl font-bold mb-8 text-center">
              How EMDR Therapy Works
            </h2>
            <div className="flex flex-wrap -mx-4">
              {/* Phase 1 */}
              <div className="w-full md:w-1/2 px-4 mb-8">
                <h3 className="text-xl font-semibold mb-2">
                  The Eight Phases of EMDR
                </h3>
                <ul className="list-disc list-inside text-base">
                  <li>History and Treatment Planning</li>
                  <li>Preparation</li>
                  <li>Assessment</li>
                  <li>Desensitization</li>
                  <li>Installation</li>
                  <li>Body Scan</li>
                  <li>Closure</li>
                  <li>Reevaluation</li>
                </ul>
              </div>
              {/* Image or Illustration */}
              <div className="w-full md:w-1/2 px-4 mb-8">
                <img
                  src="/images/how-emdr-works.webp"
                  alt="EMDR Process"
                  className="w-full h-auto"
                />
              </div>
            </div>
            <p className="text-base">
              EMDR therapy involves recalling distressing events while receiving
              bilateral sensory input, such as side-to-side eye movements or
              taps. This process helps reprocess traumatic memories, reducing
              their lingering effects and allowing clients to develop healthier
              responses.
            </p>
          </div>
        </section>

        {/* Benefits of EMDR */}
        <section className="bg-gray-50 py-12">
          <div className="max-w-screen-xl mx-auto px-4 text-center">
            <h2 className="text-2xl font-bold mb-8">
              Benefits of EMDR Therapy
            </h2>
            <div className="flex flex-wrap -mx-4">
              {/* Benefit 1 */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="p-6 bg-white rounded-lg shadow-lg">
                  <img
                    src="/images/effective-treatment.webp"
                    alt="Effective Treatment"
                    className="mx-auto mb-4 w-16 h-16"
                  />
                  <h3 className="text-xl font-semibold mb-2">
                    Effective Treatment
                  </h3>
                  <p className="text-base">
                    Proven to reduce symptoms of PTSD, anxiety, and depression.
                  </p>
                </div>
              </div>
              {/* Benefit 2 */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="p-6 bg-white rounded-lg shadow-lg">
                  <img
                    src="/images/quick-results.webp"
                    alt="Quick Results"
                    className="mx-auto mb-4 w-16 h-16"
                  />
                  <h3 className="text-xl font-semibold mb-2">Quick Results</h3>
                  <p className="text-base">
                    Clients often notice improvements after just a few sessions.
                  </p>
                </div>
              </div>
              {/* Benefit 3 */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="p-6 bg-white rounded-lg shadow-lg">
                  <img
                    src="/images/non-invasive.webp"
                    alt="Non-Invasive"
                    className="mx-auto mb-4 w-16 h-16"
                  />
                  <h3 className="text-xl font-semibold mb-2">Non-Invasive</h3>
                  <p className="text-base">
                    No need for extensive talk therapy or medication.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Scientific Evidence */}
        <section className="py-12">
          <div className="max-w-screen-md mx-auto px-4 text-center">
            <h2 className="text-2xl font-bold mb-4">
              Scientific Backing of EMDR
            </h2>
            <p className="text-base mb-4">
              EMDR therapy is recognized as an effective form of treatment by
              numerous organizations, including:
            </p>
            <ul className="list-disc list-inside text-base mb-4">
              <li>World Health Organization (WHO)</li>
              <li>American Psychiatric Association (APA)</li>
              <li>Department of Veterans Affairs (VA)</li>
            </ul>
            <p className="text-base">
              Extensive research has demonstrated its efficacy in treating
              trauma and other mental health issues.
            </p>
          </div>
        </section>

        {/* Call to Action */}
        <section className="bg-primary text-white py-12">
          <div className="max-w-screen-md mx-auto px-4 text-center">
            <h2 className="text-2xl font-bold mb-4">
              Experience the Benefits of EMDR Therapy
            </h2>
            <p className="text-base mb-8">
              Join us and start your journey towards healing and mental
              wellness.
            </p>
            <Link
              to="/subscribe"
              className="inline-block bg-white text-accent font-semibold py-3 px-6 rounded-full hover:bg-gray-100"
            >
              Subscribe Now
            </Link>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default AboutEMDR;
