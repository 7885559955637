import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { toast } from "react-toastify";
import posthog from "posthog-js";
import DynamicTitle from "./DynamicTitle";

function SignInSignUp() {
  const { login } = useAuth();
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const toggleForm = () => {
    setIsSignUp(!isSignUp);
    // Clear form fields and errors when toggling
    setEmail("");
    setPassword("");
    setFirstName("");
    setLastName("");
    setEmailError("");
    setPasswordError("");
    setFirstNameError("");
    setLastNameError("");
    setSuccessMessage("");
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePassword = (password) => {
    const passwordPattern =
      /^(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
    console.log("This password is " + passwordPattern.test(password));
    return passwordPattern.test(password);
    // return true
  };

  const validateName = (name) => {
    return name.trim().length > 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const apiUrl = isSignUp ? "/api/register" : "/api/login";
    console.log(`Sending request to: ${apiUrl}`);

    // Validate email
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    } else {
      setEmailError("");
    }

    // Validate password
    if (!validatePassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include a number and a symbol.",
      );
      return;
    } else {
      setPasswordError("");
    }

    // Validate first name and last name for sign up
    if (isSignUp) {
      if (!validateName(firstName)) {
        setFirstNameError("Please enter your first name.");
        return;
      } else {
        setFirstNameError("");
      }

      if (!validateName(lastName)) {
        setLastNameError("Please enter your last name.");
        return;
      } else {
        setLastNameError("");
      }
    }

    try {
      if (isSignUp) {
        const response = await axios({
          method: "post",
          url: apiUrl,
          data: { email, password, first_name: firstName, last_name: lastName },
          headers: {
            "Content-Type": "application/json",
          },
        });

        posthog.capture("user_signed_up", {
          login_type: "email",
        });

        console.log(response.data);
        setSuccessMessage(
          "User created successfully. Redirecting to login....",
        );
        setTimeout(() => {
          setIsSignUp(false);
          setSuccessMessage("");
        }, 2000);
      } else {
        // Use the login function from AuthContext for sign-in
        await login(email, password);
        posthog.identify(email, {
          email: email,
        });
        // console.log("Stored Token:", localStorage.getItem("token"));
        navigate("/sessions");
      }
    } catch (error) {
      console.error(isSignUp ? "Registration failed:" : "Login failed:", error);
      toast.error(error.response?.data?.error || error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <DynamicTitle>Welcome</DynamicTitle>
      <Header />
      <main className="flex-grow mx-auto px-4 sm:px-6 lg:px-8 max-w-screen-xl mt-4 mb-4 flex flex-col items-center">
        <h1 className="text-2xl font-bold text-center">
          {isSignUp ? "Create an Account" : "Sign In"}
        </h1>
        {successMessage && (
          <p className="text-green-500 text-center my-2">{successMessage}</p>
        )}
        <form onSubmit={handleSubmit} className="flex flex-col">
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
            />
            {emailError && (
              <span className="text-red-500 text-sm">{emailError}</span>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              id="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
            />
            {passwordError && (
              <span className="text-red-500 text-sm">{passwordError}</span>
            )}
          </div>
          {isSignUp && (
            <>
              <div className="mb-4">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name
                </label>
                <input
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
                />
                {firstNameError && (
                  <span className="text-red-500 text-sm">{firstNameError}</span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name
                </label>
                <input
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
                />
                {lastNameError && (
                  <span className="text-red-500 text-sm">{lastNameError}</span>
                )}
              </div>
            </>
          )}
          <button
            type="submit"
            className="subscribe-button text-white py-2 rounded-md transition"
          >
            {isSignUp ? "Sign Up" : "Sign In"}
          </button>
        </form>
        <p className="mt-4 text-center">
          {isSignUp ? (
            <>
              Already have an account?{" "}
              <button
                onClick={toggleForm}
                className="text-primary hover:underline"
              >
                Sign In
              </button>
            </>
          ) : (
            <>
              Don't have an account?{" "}
              <button
                onClick={toggleForm}
                className="text-primary hover:underline"
              >
                Create an account
              </button>
            </>
          )}
          <p>
            Forgot your password?{" "}
            <button
              onClick={()=> navigate("/forgot-password")}
              className="text-primary hover:underline"
            >
              Get a reset link.
            </button>
          </p>
        </p>
      </main>
      <Footer />
    </div>
  );
}

export default SignInSignUp;
