import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import DynamicTitle from "./DynamicTitle";

function About() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <DynamicTitle>About</DynamicTitle>

      <main className="flex-grow">
        {/* Introduction Section */}
        <section className="bg-gray-50 py-12">
          <div className="max-w-screen-md mx-auto px-4 text-center">
            <h1 className="text-3xl font-bold mb-4">Our Journey to Healing</h1>
            <p className="text-lg mb-8">
              <em>
                RealignMind: Making Transformative Therapy Accessible to All
              </em>
            </p>
            <p className="text-base">
              At RealignMind, we're dedicated to making transformative therapy
              accessible to everyone. Our mission is rooted in personal
              experience and a profound desire to help others find the healing
              they deserve.
            </p>
          </div>
        </section>

        {/* Personal Story Section */}
        <section className="py-12">
          <div className="max-w-screen-md mx-auto px-4">
            <h2 className="text-2xl font-bold mb-4 text-center">
              A Personal Journey of Transformation
            </h2>
            <p className="text-base mb-4">
              For most of my life, I've been in and out of therapy, tirelessly
              working on aspects of myself that seemed perpetually stuck.
              Despite countless sessions, some things felt immovable—as if
              they'd always be a part of me.
            </p>
            <p className="text-base mb-4">
              That all changed when I tried Accelerated Resolution Therapy
              (ART). During the session, I couldn't shake the feeling that it
              wasn't working. I thought, "Oh great, this isn't working. I must
              be doing it wrong." But then, suddenly, everything shifted. I
              experienced a profound revelation, overcome by a simple thought
              that allowed me to forgive and move forward.
            </p>
            <p className="text-base">
              This moment was life-changing. I knew immediately that I wanted to
              help others experience the same breakthrough. I envisioned a way
              to make this powerful therapy more accessible to those who need
              it.
            </p>
          </div>
        </section>

        {/* The Birth of RealignMind Section */}
        <section className="bg-gray-50 py-12">
          <div className="max-w-screen-md mx-auto px-4">
            <h2 className="text-2xl font-bold mb-4 text-center">
              Transforming Experience into a Mission
            </h2>
            <p className="text-base mb-4">
              After years of study and dedication, RealignMind was born. Our
              platform is the culmination of my personal journey and
              professional efforts to bring effective therapy to more people. We
              specialize in EMDR (Eye Movement Desensitization and Reprocessing)
              therapy, offering both guided and unguided sessions to support you
              on your path to healing.
            </p>
          </div>
        </section>

        {/* Our Commitment Section */}
        <section className="py-12">
          <div className="max-w-screen-md mx-auto px-4 text-center">
            <h2 className="text-2xl font-bold mb-4">Why We Do What We Do</h2>
            <p className="text-base mb-4">
              We believe that healing should be accessible, effective, and
              empowering. Our commitment is to provide you with the tools and
              support you need to overcome past traumas and move toward a
              healthier, happier future.
            </p>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="bg-accent text-white py-12">
          <div className="max-w-screen-md mx-auto px-4 text-center">
            <h2 className="text-2xl font-bold mb-4">Join Us on This Journey</h2>
            <p className="text-base mb-8">
              We're here to support you every step of the way. If you're ready
              to experience the transformative power of EMDR therapy, we invite
              you to join our community.
            </p>
            <Link
              to="/subscribe"
              className="inline-block bg-white text-accent font-semibold py-3 px-6 rounded-full hover:bg-gray-100"
            >
              Start Your Healing Journey
            </Link>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default About;
