import React, { useState } from "react";

function MoodTracker({ onSubmit }) {
  const [moodRating, setMoodRating] = useState(5);
  const [note, setNote] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(moodRating, note);
  };

  return (
    // Full-screen greyed-out overlay
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      {/* Centered form container */}
      <form
        className="flex flex-col items-center max-w-lg w-full mx-4 p-4 bg-background shadow-md rounded"
        onSubmit={handleSubmit}
      >
        <div className="mb-4 w-full">
          <label className="block text-lg font-semibold mb-2 text-text">
            How Are You Currently Feeling?
          </label>
          <input
            type="range"
            min="1"
            max="10"
            value={moodRating}
            onChange={(e) => setMoodRating(e.target.value)}
            className="w-full accent-accent hover:accent-primary"
          />
          <div className="slider-labels flex justify-between mb-3">
            <span className="slider-label">1</span>
            <span className="slider-label">2</span>
            <span className="slider-label">3</span>
            <span className="slider-label">4</span>
            <span className="slider-label">5</span>
            <span className="slider-label">6</span>
            <span className="slider-label">7</span>
            <span className="slider-label">8</span>
            <span className="slider-label">9</span>
            <span className="slider-label">10</span>
          </div>
        </div>

        <textarea
          placeholder="Additional notes..."
          value={note}
          onChange={(e) => setNote(e.target.value)}
          className="border border-text rounded p-2 w-full mb-4 text-text"
        />

        <p className="text-sm text-text mb-4">
          By submitting this form, and recording your session, you acknowledge
          that you have read and agree to our{" "}
          <a
            href="/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
            className="text-accent underline"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            href="/disclaimer"
            target="_blank"
            rel="noopener noreferrer"
            className="text-accent underline"
          >
            Legal Disclaimer
          </a>
          .
        </p>
        <button
          type="submit"
          className="subscribe-button font-bold py-2 px-4 rounded bg-accent hover:bg-primary text-white transition"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default MoodTracker;
