import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DynamicTitle from "./DynamicTitle";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const apiUrl = "/api/send-magic-link";
    console.log(`Sending request to: ${apiUrl}`);

    try {
      const response = await axios.post(apiUrl, {
        email,
        // test: true,
      });

      console.log("Response from server:", response.data);
      toast.success(response.data);
      setMessage(response.data);
    } catch (error) {
      console.error('Password Reset Failed', error);
      toast.error(error.response?.data?.error || error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <DynamicTitle>Forgot Password</DynamicTitle>
      <Header />
      <main className="flex-grow mx-auto px-4 sm:px-6 lg:px-8 max-w-screen-xl mt-4 mb-4 flex flex-col items-center">
        {
        // Dynamically Render Form or Message, below
        }
        {!message && <ForgotPasswordForm
                       handleSubmit={handleSubmit}
                       email={email}  
                       setEmail={setEmail}  
                       />}
        {message && <ForgotPasswordSent/>}
        
      </main>
      <Footer />
    </div>
  );
}

function ForgotPasswordForm({handleSubmit, email, setEmail}){
  return(<div>
    <h1 className="text-2xl font-bold text-center">Enter your email</h1>
    <form onSubmit={handleSubmit} className="flex flex-col">
      <div className="mb-4">
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Enter your email
        </label>
        <input
          id="email"
          type="email"
          placeholder="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
        />
      </div>
      <button
        type="submit"
        className="subscribe-button text-white py-2 rounded-md transition"
      >
        Send Reset Link
      </button>
    </form>
  </div>)
}

function ForgotPasswordSent(){
  const Navigate = useNavigate();
  
  return(<div>
    <h1 className="text-2xl font-bold text-center">Check your email</h1>
    <p className="text-center my-2">We've sent you a link to reset your password.</p>
    <button
      className="subscribe-button text-white py-2 rounded-md transition"
      onClick= {() => Navigate("/login")}
      >
      Return to Login
    </button>
  </div>)
}

export default ForgotPassword;
