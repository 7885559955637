import React, { useEffect, useCallback } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import ARTSession from "./ARTSession";
import UnguidedSession from "./UnguidedSession";
import Footer from "./Footer";
import Header from "./Header";
import DynamicTitle from "./DynamicTitle";

function SessionSelection() {
  const navigate = useNavigate();

  // Simple token check
  const validateToken = useCallback(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return false;
    }
    return true;
  }, [navigate]);

  useEffect(() => {
    validateToken();
  }, [validateToken]);

  return (
    <div className="session-selection-page flex flex-col min-h-screen">
      <DynamicTitle>Session Select</DynamicTitle>
      <Header />
      <main className="session-selection-main flex-grow mx-auto p-4 sm:px-6 lg:px-8 max-w-screen-xl mt-4 mb-4">
        <div className="grid gap-6 md:grid-cols-2">
          <div className="card bg-white shadow-md p-6 rounded-lg">
            <h3 className="text-lg font-bold mb-2">ART Session</h3>
            <p className="mb-4">
              ART is an accelerated resolution therapy, it specializes in
              helping individuals quickly resolve distressing memories.
            </p>
            <Link to="art">
              <button className="w-full bg-accent text-white font-bold py-2 rounded hover:bg-primary transition">
                Start ART Session
              </button>
            </Link>
          </div>
          <div className="card bg-white shadow-md p-6 rounded-lg">
            <h3 className="text-lg font-bold mb-2">Unguided Session</h3>
            <p className="mb-4">
              A self-guided session follows no script and allows for personal
              exploration with the freedom to tailor your experience.
            </p>
            <Link to="unguided">
              <button className="w-full bg-accent text-white font-bold py-2 rounded hover:bg-primary transition">
                Start Unguided Session
              </button>
            </Link>
          </div>
        </div>
      </main>

      <Routes>
        <Route path="art" element={<ARTSession />} />
        <Route path="unguided" element={<UnguidedSession />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default SessionSelection;
