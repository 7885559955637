
import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import DynamicTitle from "./DynamicTitle";

function SessionHistory() {
  const [sessions, setSessions] = useState([]);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState("dateDesc");
  const [activeFilters, setActiveFilters] = useState(new Set());
  const [sessionTypes, setSessionTypes] = useState(new Set());
  const [showFilters, setShowFilters] = useState(false);
  const [viewMode, setViewMode] = useState('cards'); // 'cards' or 'table'

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await axios.get("/api/sessions/history", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        setSessions(response.data.session_history);
        const types = new Set(response.data.session_history.map(session => session.session_type));
        setSessionTypes(types);
        setActiveFilters(types);
      } catch (error) {
        setError("Error fetching session history");
        console.error("Error fetching history:", error);
      }
    };
    fetchSessions();
  }, []);

  const sortSessions = (sessions) => {
    return [...sessions].sort((a, b) => {
      switch (sortBy) {
        case "dateAsc":
          return new Date(a.session_start) - new Date(b.session_start);
        case "dateDesc":
          return new Date(b.session_start) - new Date(a.session_start);
        case "length":
          const aLength = a.session_end 
            ? new Date(a.session_end) - new Date(a.session_start)
            : 0;
          const bLength = b.session_end 
            ? new Date(b.session_end) - new Date(b.session_start)
            : 0;
          return bLength - aLength;
        default:
          return 0;
      }
    });
  };

  return (
    <div className="flex flex-col min-h-screen">
      <DynamicTitle>History</DynamicTitle>
      <Header />

      <main className="session-history-main flex-grow mx-auto p-4 sm:px-6 lg:px-8 max-w-screen-xl mt-4 mb-4">
        <div className="mb-6">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold">Your EMDR Session History</h1>
            <div className="flex gap-4 items-center">
              <select 
                className="p-2 border rounded-md shadow-sm min-w-select"
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
              >
                <option value="dateDesc">Newest First</option>
                <option value="dateAsc">Oldest First</option>
                <option value="length">By Length</option>
              </select>
              <button
                onClick={() => setViewMode(viewMode === 'cards' ? 'table' : 'cards')}
                className="p-2 text-textSecondary hover:text-text border rounded-md min-w-button"
              >
                {viewMode === 'cards' ? 'Table View' : 'Card View'}
              </button>
            </div>
          </div>
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="text-sm text-gray-600 hover:text-gray-800 mb-2"
          >
            {showFilters ? 'Hide Filters ▼' : 'Show Filters ▶'}
          </button>
          {showFilters && (
            <div>
              <h2 className="font-semibold mb-2">Session Type</h2>
              <div className="flex gap-4 flex-wrap">
              {Array.from(sessionTypes).map((type) => (
                <label key={type} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={activeFilters.has(type)}
                    onChange={(e) => {
                      const newFilters = new Set(activeFilters);
                      if (e.target.checked) {
                        newFilters.add(type);
                      } else {
                        newFilters.delete(type);
                      }
                      setActiveFilters(newFilters);
                    }}
                    className="form-checkbox h-4 w-4"
                  />
                  {type}
                </label>
              ))}
            </div>
            </div>
          )}
        </div>
        {error && <p className="text-red-500">{error}</p>}
        {!error && sessions.length === 0 && <p>No sessions found.</p>}
        {viewMode === 'cards' ? (
          <div className="grid gap-4 md:grid-cols-3 lg:grid-cols-4 mt-4">
            {sortSessions(sessions)
              .filter(session => activeFilters.has(session.session_type))
              .map((session) => (
              <div
                className="session-card bg-background border border-primary rounded-lg p-4 shadow-md"
                key={session.session_id}
              >
                <h2 className="font-semibold text-lg">{session.session_type}</h2>
                <p>
                  <strong>Start Time:</strong>{" "}
                  {new Date(session.session_start).toLocaleString()}
                </p>
                <p>
                  <strong>End Time:</strong>{" "}
                  {session.session_end
                    ? new Date(session.session_end).toLocaleString()
                    : "In Progress"}
                </p>
                <p>
                  <strong>Mood Pre:</strong> {session.mood_pre}
                </p>
                <p>
                  <strong>Mood Post:</strong> {session.mood_post}
                </p>
                <p>
                  <strong>Comments Before:</strong> {session.comment_pre}
                </p>
                <p>
                  <strong>Comments After:</strong> {session.comment_post}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <div className="mt-4 overflow-x-auto">
            <table className="min-w-full border-collapse">
              <thead>
                <tr className="bg-background border-b border-primary">
                  <th className="p-3 text-left">Type</th>
                  <th className="p-3 text-left">Start Time</th>
                  <th className="p-3 text-left">End Time</th>
                  <th className="p-3 text-left">Mood Pre</th>
                  <th className="p-3 text-left">Mood Post</th>
                  <th className="p-3 text-left">Comments Before</th>
                  <th className="p-3 text-left">Comments After</th>
                </tr>
              </thead>
              <tbody>
                {sortSessions(sessions)
                  .filter(session => activeFilters.has(session.session_type))
                  .map((session) => (
                  <tr key={session.session_id} className="border-b border-primary hover:bg-background">
                    <td className="p-3">{session.session_type}</td>
                    <td className="p-3">{new Date(session.session_start).toLocaleString()}</td>
                    <td className="p-3">{session.session_end ? new Date(session.session_end).toLocaleString() : "In Progress"}</td>
                    <td className="p-3">{session.mood_pre}</td>
                    <td className="p-3">{session.mood_post}</td>
                    <td className="p-3">{session.comment_pre}</td>
                    <td className="p-3">{session.comment_post}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </main>

      <Footer />
    </div>
  );
}

export default SessionHistory;
