import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import RegistrationForm from "./RegistrationForm";
import DynamicTitle from "./DynamicTitle";

function Confirmation() {
  return (
    <div className="flex flex-col min-h-screen">
      <DynamicTitle>Confirmation</DynamicTitle>
      <Header />

      <main className="flex-grow mx-auto px-4 sm:px-6 lg:px-8 max-w-screen-xl mt-4 mb-4 flex flex-col items-center">
        <h1 className="text-2xl font-bold mb-6 text-center">
          Thank You for Subscribing!
        </h1>
        <p className="text-lg mb-4 text-center text-gray-700">
          Your subscription is now active. Please complete the sign-up process
          to access your EMDR sessions.{" "}
          <strong>MAKE SURE TO USE THE SAME EMAIL YOU PURCHASED WITH!</strong>
        </p>

        {/* Integrate the Sign-Up Form Here */}
        <div className="w-full max-w-md">
          <RegistrationForm />
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default Confirmation;
